@import 'assets/styles';

.service {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  font-size: 14px;
  line-height: 1.4;
}

.price {
  font-size: 14px;
  line-height: 1.4;
  font-style: italic;
  margin-top: 4px;

  span {
    font-weight: bold;
  }
}

.actions {
  display: flex;
  gap: 10px;
}

.button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  appearance: none;
  border: none;
  border-radius: 0.75rem;
  cursor: pointer;
  background-color: var(--button-color, $button-color);

  &:disabled {
    opacity: .4;
    cursor: progress;
  }
}

.buttonGreen {
  background-color: green;
}

.buttonRed {
  background-color: red;
}

.icon {
  display: flex;

  path {
    fill: var(--button-text-color, $button-text-color);
  }
}

.loadingIcon {
  circle {
    stroke: var(--button-text-color, $button-text-color);
  }
}
