* {
  box-sizing: border-box;
}

body {
  font-family: var(--font-family);
  background-color: var(--bg-color, $bg-color);
  color: var(--text-color, $text-color);
}

a {
  color: var(--link-color);
}
