@import 'assets/styles';

.label {
  display: block;
}

.text {
  display: block;
  margin-bottom: 14px;
}

.fieldWrapper {
  position: relative;
}

.field {
  width: 100%;
  height: 50px;
  border: 1px solid var(--hint-color, $hint-color);
  border-radius: var(--border-radius);
  padding: 0 20px;

  &::placeholder {
    color: var(--hint-color, $hint-color);
  }

  &:focus {
    outline: none;
    border-color: var(--button-color, $button-color);
  }
}

.loadingIcon {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 2;
  transform: translateY(-50%);

  circle {
    stroke: var(--button-color, $button-color);
  }
}
