@import 'assets/styles';

.page {
  text-align: center;
  padding: 20px 20px 78px;
  position: relative;
  min-height: var(--tg-viewport-height, $viewport-height);
}

.title {
  margin: 0 0 30px;
  font-weight: normal;
}

.error {
  color: red;
  margin: 30px 0;
}

.selectedServicesLoading {
  display: inline-flex;
  align-items: center;
}

.loadingIcon {
  circle {
    stroke: var(--button-color, $button-color);
  }
}

.total {
  text-align: left;
  margin-top: 16px;

  span {
    font-weight: bold;
    font-style: italic;
  }
}

.footer {
  width: 100%;
  display: flex;
  position: fixed;
  left: 0;
  top: calc(var(--tg-viewport-height) - 60px);
}

.footerButton {
  width: 50%;
}

.backButton {
  height: 60px;
  color: var(--button-text-color, $button-text-color);
  background-color: var(--button-color, $button-color);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
}

.selectedCounter {
  width: 50%;
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--text-color, $text-color);
  background-color: var(--secondary-bg-color, $secondary-bg-color);
  padding: 0 10px;
  text-decoration: none;
}
