@import 'assets/styles';

.page {
  width: 100%;
  height: var(--tg-viewport-height, $viewport-height);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}

.logo {
  width: 114px;
  min-width: 114px;
}
